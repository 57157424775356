import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { firebaseConfig } from './firebaseConfig';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration


// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);


export async function getUsersByUserCode(usercode, setter) {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const q = query(collection(db, "users"), where('usercodes', 'array-contains', usercode));

    onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            let user = doc.data()
            user.uid = doc.id
            users.push(user)
        });
        // order users by user.authEmail and user.disabled
        users.sort((a, b) => {
            if (a.authEmail < b.authEmail) {
                return -1
            }
            if (a.authEmail > b.authEmail) {
                return 1
            }
            return 0
        })
        users.sort((a, b) => {
            if (a.disabled < b.disabled) {
                return -1
            }
            if (a.disabled > b.disabled) {
                return 1
            }
            return 0
        })
        const userFriendlyDateAndTime = new Date().toLocaleString();
        console.log('users updated ', userFriendlyDateAndTime)
        setter(users);
    })

}