import React, { useEffect, useState } from 'react'
import UserRow from '../UserRow/UserRow'
import { getUsersByUserCode } from '../../Firebase/firebase'

const FleetUserList = ({ sessionId, usercode }) => {

    const [allFleetCarriers, setAllFleetCarriers] = useState([])
    const [users, setUsers] = useState([])

    // get all trucks & users for usercode
    useEffect(() => {
        let url = 'https://api.shippersedge.com/fleet-management/fleetCarriers/' + usercode
        fetch(url, {
            method: 'GET',
            headers: {
                'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            }
        })
            .then(res => res.json())
            .then(data => {
                // sort fleet carriers by name
                data.sort((a, b) => {
                    // console.log('a.carriername', a.carriername)
                    if (a.carriername < b.carriername) {
                        return -1
                    }
                    if (a.carriername > b.carriername) {
                        return 1
                    }
                    return 0
                })
                setAllFleetCarriers(data)
            })
            .catch(err => {
                console.log('Error getting all carriers in FleetUserList.js ', err)
            })
        // get users from firebase
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getUsers() {
        getUsersByUserCode(usercode, setUsers)
        return true
    }


    if (users.length && allFleetCarriers.length) {
        return (

            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Login</th>
                            <th scope="col">Role</th>
                            <th scope="col">Password</th>
                            <th scope="col">Truck Assignments</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <React.Fragment key={index}>
                                <UserRow user={user} index={index} sessionId={sessionId} allFleetCarriers={allFleetCarriers} />
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

        )

    } else {
        return 'Loading...'
    }
}
export default FleetUserList