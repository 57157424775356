import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';

const AddUser = ({ sessionId, usercode }) => {

    const [show, setShow] = useState(false);
    const [newUserName, setNewUserName] = useState('')
    const [newUserPassword, setNewUserPassword] = useState('')
    const [newUserResult, setNewUserResult] = useState(' ')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCreateNewUser = () => {
        // console.log('handleCreateNewUser', newUserName, newUserPassword)
        setNewUserResult(' ')
        let saving = <div className="alert alert-info" role="alert">Saving...</div>
        setNewUserResult(saving)
        if (newUserPassword.length < 6) {
            const failure = <div className="alert alert-warning" role="alert">Password must be at least 6 characters.</div>
            setNewUserResult(failure)
            timeoutAlertAfterPasswordChange()
            return
        }
        if (newUserName.length < 8) {
            const failure = <div className="alert alert-warning" role="alert">Username must be at least 8 characters.</div>
            setNewUserResult(failure)
            timeoutAlertAfterPasswordChange()
            return
        }
        let password = newUserPassword
        let username = newUserName
        let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/createUser?sessionId=' + sessionId + '&email=' + username.toString() + '&password=' + password.toString()
        // console.log('url', url)
        fetch(url)
            .then(res => res.status)
            .then(data => {
                const success = <div className="alert alert-success" role="alert">User created.</div>
                const failure = <div className="alert alert-danger" role="alert">Error creating user. </div>
                if (data === 200) {
                    setNewUserResult(success)
                    timeoutAlertAfterPasswordChange()
                } else {
                    setNewUserResult(failure)
                    timeoutAlertAfterPasswordChange()
                }
            })
    }

    const timeoutAlertAfterPasswordChange = () => {
        setTimeout(() => {
            setNewUserResult(' ')
        }, 20000)
    }

    return (
        <>
            <button type="button" className="btn btn-outline-success btn-sm" onClick={handleShow}>

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-add" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                    <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                </svg>
                &nbsp; Add User
            </button>
            {/* Modal for changing password */}
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Fleet User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            {/* <span className="input-group-text" id="basic-addon1">{user.email}</span> */}
                            <input type="text" className="form-control" placeholder="username" aria-label="username" aria-describedby="basic-addon1"
                                onChange={(e) => setNewUserName(e.target.value)}
                                value={newUserName}
                            />
                            <input type="text" className="form-control" placeholder="password" aria-label="password" aria-describedby="basic-addon1"
                                onChange={(e) => setNewUserPassword(e.target.value)}
                                value={newUserPassword}
                            />
                        </div>
                        {newUserResult}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleCreateNewUser}>
                            Create New User
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        </>
    )
}

export default AddUser