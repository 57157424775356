import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

const CarrierCheckItem = ({ user, fleetCarrier, index }) => {

    const queryClient = useQueryClient()

    const [truckAssignmentResult, setTruckAssignmentResult] = useState(' ')

    const carrierShouldBeChecked = (carrier) => {
        let checked = false
        if (user.trucks) {
            user.trucks.forEach(truck => {
                if (truck.name === carrier.carriername) {
                    checked = true
                }
            })
        }
        return checked
    }

    const [checked, setChecked] = useState(carrierShouldBeChecked(fleetCarrier))

    const saving = <>&nbsp;&nbsp;<div className="spinner-border text-primary spinner-border-sm ml-5" role="status"><span className="visually-hidden">Loading...</span></div></>
    const success = ""


    // <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green" className="bi bi-check2 ml-5" viewBox="0 0 16 16">
    //     <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    // </svg>
    const failure = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-bug" viewBox="0 0 16 16">
        <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
    </svg>


    const refreshGetUsers = () => {
        // console.log('refreshGetUsers')
        queryClient.invalidateQueries('getUsers')
    }

    const handleTruckAssignment = (e, fleetCarrier) => {
        // check if checked
        let isChecked = e.target.checked

        if (isChecked) {
            // add truck to user

            setTruckAssignmentResult(saving)
            let uid = user.uid
            // console.log('uid', uid)
            let truckObj = {
                carrierid: fleetCarrier.carrierid,
                name: fleetCarrier.carriername,
            }

            let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/addTruckToUser?uid=' + uid
            // post to addTruckToUser
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(truckObj)
            })
                .then(res => res.status)
                .then(data => {
                    // console.log('addTruckToUser data', data)

                    if (data === 200) {
                        setTruckAssignmentResult(success)
                        setChecked(true)
                        timeoutAlertAfterTruckAssignment()
                        refreshGetUsers()
                    } else {
                        setTruckAssignmentResult(failure)
                        setChecked(false)
                        timeoutAlertAfterTruckAssignment()
                    }
                })
        } else {
            // remove truck from user

            setTruckAssignmentResult(saving)
            let uid = user.uid
            let truckObj = {
                carrierid: fleetCarrier.carrierid,
                name: fleetCarrier.carriername,
            }
            let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/removeTruckFromUser?uid=' + uid
            // post to removeTruckFromUser

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(truckObj)
            })
                .then(res => res.status)
                .then(data => {

                    if (data === 200) {
                        setTruckAssignmentResult(success)
                        setChecked(false)
                        timeoutAlertAfterTruckAssignment()
                        refreshGetUsers()
                    } else {
                        setTruckAssignmentResult(failure)
                        setChecked(true)
                        timeoutAlertAfterTruckAssignment()
                    }
                })
        }
    }

    const timeoutAlertAfterTruckAssignment = () => {
        setTimeout(() => {
            setTruckAssignmentResult(' ')
        }, 20000)
    }


    return (

        <div className='d-flex flex-row' key={index}>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={checked}
                    onChange={(e) => handleTruckAssignment(e, fleetCarrier)}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {fleetCarrier.carriername}
                </label>
                {truckAssignmentResult}
                {/* <button onClick={() => refreshGetUsers()}>Refresh?</button> */}
            </div>
        </div>
    )
}

export default CarrierCheckItem