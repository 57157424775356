import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CarrierCheckItem from '../CarrierCheckItem/CarrierCheckItem';
import DeleteUser from '../DeleteUser/DeleteUser';

const UserRow = ({ user, index, sessionId, allFleetCarriers }) => {

    const [show, setShow] = useState(false);

    const [newPassword, setNewPassword] = useState('')
    const [passwordChangeResult, setPasswordChangeResult] = useState(' ')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTruckAssignment, setShowTruckAssignment] = useState(false)
    const handleShowTruckAssignment = () => setShowTruckAssignment(true)
    const handleCloseTruckAssignment = () => setShowTruckAssignment(false)

    const [showRoleAssignment, setShowRoleAssignment] = useState(false)
    const handleShowRoleAssignment = () => setShowRoleAssignment(true)
    const handleCloseRoleAssignment = () => setShowRoleAssignment(false)
    const [roleChangeResult, setRoleChangeResult] = useState(' ')


    const [showEnabledModal, setShowEnabledModal] = useState(false)
    const handleShowEnabledModal = () => setShowEnabledModal(true)
    const handleCloseEnabledModal = () => setShowEnabledModal(false)
    const [enabledChangeResult, setEnabledChangeResult] = useState(' ')

    const saving = <div className="alert alert-info" role="alert">Saving...</div>
    const success = <div className="alert alert-success" role="alert">Update successful!</div>
    const failure = <div className="alert alert-danger" role="alert">Error updating. </div>




    // set new password 
    const handleChangePassword = () => {
        setPasswordChangeResult(' ')

        setPasswordChangeResult(saving)
        if (newPassword.length < 6) {
            const failure = <div className="alert alert-warning" role="alert">Password must be at least 6 characters.</div>
            setPasswordChangeResult(failure)
            timeoutAlertAfterPasswordChange()
            return
        }
        let password = newPassword
        let uid = user.uid
        let url = ' https://us-central1-drivertelemetry.cloudfunctions.net/resetUserPassword?sessionId=' + sessionId + '&uid=' + uid + '&password=' + password.toString()
        // console.log('url', url)
        fetch(url)
            .then(res => res.status)
            .then(data => {


                if (data === 200) {
                    setPasswordChangeResult(success)
                    timeoutAlertAfterPasswordChange()
                } else {
                    setPasswordChangeResult(failure)
                    timeoutAlertAfterPasswordChange()
                }
            })
    }

    const timeoutAlertAfterPasswordChange = () => {
        setTimeout(() => {
            setPasswordChangeResult(' ')
        }, 20000)
    }

    const handleChangeUserRole = (role) => {
        setRoleChangeResult(saving)
        let uid = user.uid
        let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/changeUserRole?sessionId=' + sessionId + '&uid=' + uid + '&role=' + role
        // console.log('url', url)
        fetch(url)

            .then(res => res.status)
            .then(data => {
                // console.log('data', data)
                if (data === 200) {
                    // console.log(success)
                    setRoleChangeResult(success)
                    timoutAfterRoleChange()
                } else {
                    // console.log('error')
                    setRoleChangeResult(failure)
                    timoutAfterRoleChange()
                }
            })
    }


    const timoutAfterRoleChange = () => {
        setTimeout(() => {
            setRoleChangeResult(' ')
        }, 20000)
    }

    const driverRadioChecked = (user) => {
        if (user.role === 'driver') {
            return true
        } else {
            return false
        }
    }

    const adminRadioChecked = (user) => {
        if (user.role === 'admin') {
            return true
        } else {
            return false
        }
    }


    const handleChangeUserEnabled = (action) => {
        setEnabledChangeResult(saving)
        let disabled = false
        if (action === 'disable') {
            disabled = true
        } else {
            disabled = false
        }
        setEnabledChangeResult(saving)
        let uid = user.uid
        let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/disableEnableUser?sessionId=' + sessionId + '&uid=' + uid + '&disabled=' + disabled
        // console.log('url', url)
        fetch(url)
            .then(res => res.status)
            .then(data => {
                // console.log('data', data)
                if (data === 200) {
                    console.log(success)
                    setEnabledChangeResult(success)
                    timeoutAfterEnabledChange()
                } else {
                    console.log('error')
                    setEnabledChangeResult(failure)
                    timeoutAfterEnabledChange()
                }
            })
    }

    const disableRadioChecked = (user) => {
        if (user?.disabled === true) {
            return true
        } else {
            return false
        }
    }

    const enableRadioChecked = (user) => {
        if (user?.disabled === false) {
            return true
        } else if (user?.disabled === undefined) {
            return true
        } else {
            return false
        }
    }

    const timeoutAfterEnabledChange = () => {
        setTimeout(() => {
            setEnabledChangeResult(' ')
        }, 20000)
    }


    const firstLetterCapital = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    console.log('user', user?.trucks?.length)

    let userHasTrucksAssigned = true
    if (!user?.trucks?.length) {
        userHasTrucksAssigned = false
    }

    let trukAssignBtnClass = "btn btn-outline-primary btn-sm"
    if (!userHasTrucksAssigned) {
        trukAssignBtnClass = "btn btn-outline-danger btn-sm"
    }

    return (
        <tr >
            <td style={{ verticalAlign: "middle" }}>
                <span>{user.authEmail}</span>
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <span onClick={handleShowRoleAssignment} style={{ cursor: "pointer" }}>
                    {firstLetterCapital(user.role)} &nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>

                </span>
            </td>
            <td>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleShow}>
                    Password &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </button>
            </td>

            <td>
                <button type="button" className={trukAssignBtnClass} onClick={handleShowTruckAssignment} style={{ width: '200px' }}>
                    {userHasTrucksAssigned ? <>Truck Assignments ({user?.trucks?.length})</> : 'No Trucks Assigned'}
                    &nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                </button>
            </td>
            <td style={{ verticalAlign: "middle" }} onClick={() => handleShowEnabledModal()}>
                {user?.disabled === true ?
                    <span style={{ cursor: "pointer" }}>Disabled &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </span> :
                    <span style={{ cursor: "pointer" }}>Enabled &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </span>
                }
            </td>
            {/* Modal for changing password */}
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Password For: <br />
                            {user.authEmail}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Password</span>
                                <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    value={newPassword}
                                />
                            </div>
                            <div className="form-text">Minimum 6 Characters.</div>
                        </div>
                        {passwordChangeResult}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleChangePassword}>
                            Save New Password
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
            {/* Modal for changing Truck Assignment */}
            <>
                <Modal show={showTruckAssignment} onHide={handleCloseTruckAssignment}>
                    <Modal.Header closeButton>
                        <Modal.Title>Truck Assignments For: <br />
                            {user.authEmail}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            {allFleetCarriers.map((fleetCarrier, index) => (
                                <CarrierCheckItem user={user} fleetCarrier={fleetCarrier} key={index} />
                            ))}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={handleCloseTruckAssignment}>
                            Close
                        </button>

                    </Modal.Footer>
                </Modal>
            </>
            {/* Modal for changing Role Assignment */}
            <>
                <Modal show={showRoleAssignment} onHide={handleCloseRoleAssignment} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Role Assignment For: <br />
                            {user.authEmail}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 border-bottom">
                            The difference between Driver and Admin roles are:
                            <ul>
                                <li>When the Driver selects a shipment in the ShippersEdge Fleet App the App immedately begins reporting location information to ShippersEdge.</li>
                                <li>When an Admin selects a shipment in the ShippersEdge Fleet App the App does NOT report location information (GPS) to ShippersEdge.  An admin can manually opt to report location information.</li>
                            </ul>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="roleDriver" id="roleDriver" checked={driverRadioChecked(user)} onChange={() => handleChangeUserRole('driver')} />
                                <label className="form-check-label" htmlFor="roleDriver">
                                    Driver
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="roleAdmin" id="roleAdmin" checked={adminRadioChecked(user)} onChange={() => handleChangeUserRole('admin')} />
                                <label className="form-check-label" htmlFor="roleAdmin">
                                    Admin
                                </label>
                            </div>
                            <div>
                                {roleChangeResult}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={handleCloseRoleAssignment}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
            {/* Modal for disable and enabled */}

            <>

                <Modal show={showEnabledModal} onHide={handleCloseEnabledModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>User Actions for: <br />
                            {user.authEmail}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className='mb-3'><h4>Enable / Disable User</h4></div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="enableUser" id="enableUser" checked={enableRadioChecked(user)} onChange={() => handleChangeUserEnabled('enable')} />
                                <label className="form-check-label" htmlFor="enableUser">
                                    Enabled
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="disableUser" id="disableUser" checked={disableRadioChecked(user)} onChange={() => handleChangeUserEnabled('disable')} />
                                <label className="form-check-label" htmlFor="disableUser">
                                    Disabled
                                </label>
                            </div>

                            <div>
                                {enabledChangeResult}
                            </div>
                        </div>
                        <div className="mb-3 border-top">
                            <DeleteUser user={user} sessionId={sessionId} handleCloseEnabledModal={handleCloseEnabledModal} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={handleCloseEnabledModal}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </>


        </tr>
    )
}



export default UserRow