import React from 'react'

const DeleteUser = ({ user, sessionId, handleCloseEnabledModal }) => {

    const [confirmDelete, setConfirmDelete] = React.useState(false)
    const [deleteUserStatus, setDeleteUserStatus] = React.useState('')

    const saving = <div className="alert alert-info" role="alert">Saving...</div>
    const success = <div className="alert alert-success" role="alert">Update successful!</div>
    const failure = <div className="alert alert-danger" role="alert">Error updating. </div>


    const deleteUser = () => {

        setDeleteUserStatus(saving)

        let uid = user.uid
        let url = 'https://us-central1-drivertelemetry.cloudfunctions.net/deleteUser?sessionId=' + sessionId + '&uid=' + uid
        // console.log('url', url)
        fetch(url)
            .then(res => res.status)
            .then(data => {

                // console.log('data', data)
                if (data === 200) {
                    // console.log(success)
                    handleCloseEnabledModal()
                    setDeleteUserStatus(success)
                } else {
                    // console.log('error')
                    setDeleteUserStatus(failure)
                }
            })
    }

    return (
        <div className="mt-3">
            <div className='mb-3'><h4>Delete User</h4></div>
            {!confirmDelete ?
                <>

                    <div><button type="button" class="btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete User</button></div>
                </> : null}
            {confirmDelete ?
                <>
                    <div class="alert alert-warning" role="alert">
                        Are you sure you want to delete this user?  <br />This action cannot be undone.
                    </div>
                    <div><button type="button" class="btn btn-danger" onClick={() => deleteUser()}>Confirm Delete User</button></div>
                    <div className="mt-3">
                        {deleteUserStatus}
                    </div>
                </>
                : null}
        </div>
    )
}

export default DeleteUser