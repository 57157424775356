import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import FleetUserList from '../../Components/FleetUserList/FleetUserList'
import AddUser from '../../Components/AddUser/AddUser'


const Dashboard = () => {

    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient} >
            <TheDashboard />
        </QueryClientProvider>
    )
}



function TheDashboard() {

    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');
    // let queryClient = useQueryClient()

    let url = 'https://api.shippersedge.com/authenticate/cfSession/?sessionId=' + sessionId

    const getUserAuth = fetch(url).then(res => res.json()).catch(err => {
        console.log('Error getting user auth in Dashboard.js ', err)
    })

    let oneHour = 1000 * 60 * 60
    let threeHours = oneHour * 3

    const { isLoading, error, data } = useQuery(['getUserAuth'], () => getUserAuth, { keepPreviousData: true, refetchInterval: threeHours })

    if (isLoading) return 'Loading fleet users...'

    if (error) return 'An error has occurred: ' + error.message

    if (data) {
        return (
            <div className='ms-4 mt-4'>
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h1>
                            Fleet Management
                        </h1>
                    </div>
                    <div className="d-flex align-items-end">
                        <AddUser sessionId={sessionId} usercode={data.auth.ContactID} />
                    </div>

                </div>
                <div className="alert alert-secondary mt-4" role="alert">
                    Welcome to the Fleet Managment page. Here you can add ShippersEdge Fleet App users, change the user's passwords, and change truck assignments for your ShippersEdge Fleet App users.

                </div>
                {/* <div>{sessionId}</div> */}
                <FleetUserList sessionId={sessionId} usercode={data.auth.ContactID} />
            </div>

        )
    } else {
        return (<div className='ms-4 mt-4' style={{ width: 750 }}><a href="https://http.cat/401" target="_blank" rel="noreferrer"> Unauthorized</a></div>)
    }
}

export default Dashboard